<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on">
        Division Dropper
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Division Dropper</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-select
          :items="tournament.divisions"
          v-model="selectedDivision"
          item-text="name"
          return-object
          label="Division to drop"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          text
          @click.stop="doDrop"
        >Drop It</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['tournament'],
  data () {
    return {
      dialog: false,
      selectedDivision: null
    }
  },
  methods: {
    doDrop () {
      this.dialog = false
      this.tournament.dropDivision(this.selectedDivision)
    }
  }
}
</script>
